/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5b9feb3b-db55-466b-bf8e-91639b496c49",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_jqSMAFKrs",
    "aws_user_pools_web_client_id": "tacvlohtbfeq789bf6k81t33k",
    "oauth": {
        "domain": "oauth-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.flowtrace.io/Login/,https://dev.flowtrace.io/Login/",
        "redirectSignOut": "https://app.flowtrace.io/,https://dev.flowtrace.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "5d14c820621e4f719ad03ba6c760d50a",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_user_files_s3_bucket": "client-slack-event-data145025-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "slack-connection-strings-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "eventData-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "slackMetadata-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "userStatistics-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "users-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "collaborationEvents-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "clientAccounts-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "clientMembers-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "securityTokens-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "surveyResponses-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "flowtraceBusinessAnalytics-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "webhookPayload-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "webhookCollaborationEvents-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "metrics-dev",
            "region": "eu-west-1"
        },
        {
            "tableName": "meetings-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "slackintegration",
            "endpoint": "https://bqm2t9fmfe.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "flowtrace-20200624123715-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://flowtrace-20200624123715-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;
